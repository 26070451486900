import React from 'react'
import bem from 'bem'
import css from './Page.module.scss'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import Sector from 'components/Sector'

const b = bem.scenePage(css)

const Page = ({ pageContext: page }) => {
	const { title, content, seo /*featuredImage*/ } = page
	// const image = featuredImage ? featuredImage.imageFile : null
	return (
		<Layout className={b()} title={title}>
			<SEO title={seo.title} description={seo.description} />
			<Sector>
				<div className="static-text mb-lg" dangerouslySetInnerHTML={{ __html: content }} />
			</Sector>
		</Layout>
	)
}

export default Page
